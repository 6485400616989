.ErrorParent {
    background-color: rgb(110, 107, 118);
    height: 100%
}

.ErrorMessage {
    margin-top: 3em;
    margin-left: 5em;
    margin-bottom: 1em;
    font-size: 5em;
}

/* Error for mobile and tablet */

@media (max-width: 767px) {

    .ErrorParent {
        background-color: rgb(110, 107, 118);
        width: 223%;
    }

    .ErrorMessage {
        margin-top: 3em;
        margin-left: 2em;
        margin-bottom: 1em;
        font-size: 5em;
    }
}