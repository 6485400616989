#courses {
    padding-top: 12%;
    padding-right: 12%;
    padding-bottom: 2%;
    padding-left: 12%;
    background-image: url(../public/C1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.notice{
    text-align: center;
    font-weight: bold;
    width: 100%;

    margin: 50px auto;
    padding: 30px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 1);
    border-radius: 10px;
    transform: rotateX(20deg) translateZ(30px);


    
}

.coursesTitle {
    color: rgb(31, 26, 26);
    font-weight: bolder;
    font-size: 3em;

}

.courseHeadings{
    margin-top: 2em;
    margin-bottom: 2em;
    text-align: center;
}

/* list-style-type: circle; */

.ulParent {
    line-height: 2;
}


/* courses-styles */

.beginners,
.intermediate {
    display: inline-block;
    border-radius: 3em;
    margin-top: 1.9em;
    box-shadow: inset 0 0px 59px slategray;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    transform: rotateX(20deg) translateZ(30px);
}

.beginners {
    background-color: rgb(53, 50, 56);
    color: goldenrod;


}

.intermediate {
    margin-left: 1em;
    margin-right: 1em;

}

  /* chrome desktop mode on phone */
  @media (width: 2560px) and (height: 1440px) {
    
    #courses {
        font-size: -webkit-xxx-large;
    }

    .h5, h5 {
        font-size: -webkit-xxx-large;
    }
}

/* Skill for Tablet */
@media (min-width: 540px) and (max-width: 912px) {
    #courses {
        padding-top: 37%;
}
}


/* Skill for mobile phones*/
@media (max-width: 600px) {
    .coursesTitle {
        padding: 16%;
        color: antiquewhite;
        font-size: 2em;
        font-weight: bolder;
        box-shadow: 0 56px 30px rgba(0, 0, 0, 1);
        background-image: url(../public/C3.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-top: 26%;
    }

    
.notice{
    border-radius: 0px;
    box-shadow: inset 0 0px 59px slategray;
}

    #courses {

        background-color:#CCCCCC;
        background-image: none;
        padding-left: 7vw;
        padding-right: 7vw;
        padding-top: 28%;

    }
        

    .beginners,
.intermediate {
    display: inline-block;
}

    .intermediate {
        margin-left: auto;
        margin-right: auto;
    }

    .masters {
        padding-bottom: 44em;
    }

}


/* very small screens */
@media (max-width: 350px) {
    #courses {
        padding-top: 60%;
}
}

