/* fonts.css */
@font-face {
    font-family: 'Roberto';
    src: url('../src/fonts/Roboto-Regular.ttf') format('truetype'),
         url('../src/fonts/Roboto-Black.ttf') format('truetype');
  }

body {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: "Roboto-ThinItalic", sans-serif;
}

/* Header */

.header {
    position: fixed;
    top: 0em;
    left: 0em;
    right: 0em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 17%;
    background-color: black;
    color: antiquewhite;
    z-index: 1000;
}


.logo{
    width: 197px;
    margin-left: 8%;
    margin-top: 8%;
    margin-bottom: 3%;
}

.header__nav {
    display: flex;
}

.header__nav-list {
    list-style: none;
    margin: 0;
    margin-right: 14%;
    padding: 0;
    display: flex;
}


/* header continues from here */
.header__nav-item:first-child {
    margin-left: -17%
}

.header__nav-item a {
    text-decoration: none;
    font-size: 1.2rem;
    color: goldenrod;
    font-weight: bolder;
    transition: color 0.2s ease-in-out;
    white-space: nowrap;
}

.header__nav-item a:hover {
    color: #0077cc;
}

  /* chrome desktop mode on phone */
  @media (width: 2560px) and (height: 1440px) {
    
        .logo {
            width: 70rem;
        }

        .header__nav-item a {
            font-size: 3.5rem;
        }
}




    /* profile for mobile */

    @media (max-width: 499px) {
        .header{
            height: 20%;
        }

        .header__nav-list {
            margin-left: 5%;
        }

        .header__nav-item:first-child {
            margin-left: 0px;
        }

    }

    /* Very small screens */
    @media (max-width: 350px) {

        .header__nav-list {
            margin-left: 0%;

        }

        .logo {
            width: 150px;
            margin-left: 0%;
            margin-top: -85%;
            margin-bottom: 0%;
        }

        .header{
            padding: 0%;
            flex-direction: column;
            justify-content: space-around;
            height: 31%;
        }

        .header__nav {
            margin-top: -66%;
            margin-left: 35%;
            margin-bottom: -14%;
        }

    }

    
    /* profile for mini-tablet */

@media (min-width: 500px) and (max-width: 635px) {

    .header{
        height: 20%;
    }

    .header__nav-list {
        margin-left: 40%;
    }

    .header__nav-item:first-child {
        margin-left: 0px;
    }

}

/* header for Tablet */

@media (min-width: 630px) and (max-width: 830px) {
    
    .header{
        height: 20%;
    }

    .header__nav-list {
        margin-left: 48%;
    }

    .header__nav-item:first-child {
        margin-left: 0px;
    }

}