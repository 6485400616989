.contactParent{
    background-image: url(../public/CN1.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

#contact {
    max-width: 600px;
    margin: 0 auto;
    padding-top: 15%;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;


}

.googleMapParent{
    width: 100%;
    height: 450px;
    margin-top: 10%;
}

.mapLoading{
    display: flex;
    font-size: x-large;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 450px;
    background-color: #ffffff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 1);
    border-radius: 10px;
    transform: rotateX(20deg) translateZ(30px);
}


.mapLoadingNone{
    display: none;
}

.googleMap{
    width: 100%;
    height: 0px;
    transform: scale(0,0);
}

.googleMap-2{
    width: 100%;
    height: 450px;
    transform: scale(1,1);
    transition: transform 1s ease;
}

h2 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 30px;
}

.contactMessageDiv {
    margin-bottom: 30px;
}

.contactMessage{
    font-size: large;
    padding: 5%;
    background-color: #daa5206e;
    box-shadow: 0 -9px 10px rgba(0, 0, 0, 1);
    border-radius: 10px;
    transform: rotateX(1deg) translateZ(30px);

}

.contactMessage2{
    font-size: large;
    padding: 5%;
    background-color: #daa5206e;
    box-shadow: 0 -9px 10px rgba(0, 0, 0, 1);
    border-radius: 10px;
    transform: rotateX(1deg) translateZ(30px);

}

  /* chrome desktop mode on phone */
  @media (width: 2560px) and (height: 1440px) {
    
    #contact {
        max-width: 1110px;
        padding-bottom: 40rem;
  }

  .contactMessage2 {
    font-size: -webkit-xxx-large;
  }

.googleMap-2{
    height: 900px;
}

}


/* Skill for Tablet */
@media (min-height: 1024px) and (max-height: 1368px) {

    #contact {
        padding-top: 35%;
    }
    
    .contactParent{
        height: 100vh;
    }
  }

  @media (min-width: 540px) and (max-width: 912px) {
    #contact {
        padding-top: 35%;
    }
    
}
  
/* Contact for mobile */
@media (max-width: 480px) {
    .contactParent{
        background-image: none;
        background-color:  #CCCCCC;
    }
    
    #contact {
        padding-top: 50%;
        padding-left: 6%;
        padding-right: 6%;
    }

    .mapLoading {
        font-size: large;
    }

    .contactMessageDiv {
        padding-top: 0em;
        background-image: url(../public/CN3.jpg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        margin-top: -2%;
        height: 44vh;
        border-bottom: none;

    }
    .contactMessage{
        background-color: #ffffffc7;
        padding: 1%;

        box-shadow: 0 2px 10px rgba(0, 0, 0, 1);
        transform: rotateX(270deg) translateZ(30px);
    }

    .contactMessage2{
        background-color: #ffffffc7;
        padding: 1%;
        border-radius: 0px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 1);
        transform: rotateX(20deg) translateZ(30px);
        transition: 0.8s ease;

    }
}

/* very small screens */
@media (max-width: 350px) {
    #contact, .contactParent {
        padding-top: 50%;
        width: 100vw;
}

.contactMessage, .contactMessage2 {
    font-size: small;
}
}
