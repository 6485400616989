#home {
    padding-top: 12.8vh;
    padding-right: 2.67vw;
    padding-bottom: 8vh;
    padding-left: 2.67vw;
    color: white;
    background-image: url(../public/H1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    width:100vw
}


.company-title{
    color: goldenrod;
    margin-top: 7vh;
    font-size: 70px;
    font-weight: 900;
    opacity: 0;
    animation: companyTitle 3s forwards; 
  }
  
  @keyframes companyTitle {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
}

.golden, .craft{
    color: goldenrod;
    text-shadow: -2.9px 9.2px darkslategrey;
    filter: drop-shadow(.05em .05em .1em darkslategrey);
}

.golden{
    margin-left: 70px;
}

.home-carousel{
    color: goldenrod;
    font-size: 50px;
}

  /* chrome desktop mode on phone */
  @media (width: 2560px) and (height: 1440px) {
    
.company-title {

    font-size: 200px;
}

.home-carousel {
    font-size: 130px;
}
  }

/* profile for mobile */

@media (max-width: 480px) {

    .golden{
        margin-left: 0px;
    }
    #home{
    background-image: url(../public/H3.jpg);
    padding-top: 23.8vh;
    }

    .home-carousel{
        color: goldenrod;
        font-size: 40px;
    }

}

@media (width: 540px) {
    .golden{
        margin-left: 0px;
    }
}

@media (max-width: 360px) {
    .company-title {
        font-size: 53px;
    }
}
/* profile for tablet */

@media (min-height: 1024px) and (max-height: 1368px) {
    
    #home{
        background-image: url(../public/H3.jpg);
        padding-top: 23.8vh;
    }
}
