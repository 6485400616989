.AboutUs-body {
    padding-top: 9rem;
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    background: #CCCCCC;

}

.carousel-Image{
    object-fit: cover;
    width: 60%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    transform: scale3d(1.5, 1.5, 1.5);
}

.carousel-div{
    width: 1314px;
    height: 458px;
    overflow: hidden;
}

.carousel-control-prev, .carousel-control-next, 
.carousel-indicators {
  display: none;
}

.aboutUs-div{
    margin-top: 3%;
    margin-bottom: 3%;
    margin-left: 15%;
    margin-right: 15%;
    text-align: center;
    border-left: black solid ;
    color: rgb(53, 50, 56);
    transform: rotateY(90deg);
    transform-origin: left;

    background: goldenrod; /* Fallback */
    background:
      linear-gradient(to left bottom, 
        transparent 50%, rgba(0,0,0,.4) 0)
        no-repeat 100% 0 / 2em 2em,
      linear-gradient(-135deg,
          transparent 1.5em,  #ffd5051a 0);
      }
      
      
      .aboutUs-div-2 {
        margin-top: 3%;
        margin-bottom: 3%;
        margin-left: 15%;
        margin-right: 15%;
        text-align: center;
        border-left: black solid ;
        color: black;
        display: block;
        transform: rotateY(1deg);
        transition: 0.8s ease;

        background: goldenrod; /* Fallback */
        background:
          linear-gradient(to left bottom, 
            transparent 50%, rgba(0,0,0,.4) 0)
            no-repeat 100% 0 / 2em 2em,
          linear-gradient(-135deg,
              transparent 1.5em,  #ffd5051a 0);
      }

/* Faq.css */
.faq-title{
    text-align: center;
    margin-top: 20px;
}

.faq {
    margin-left: 3%;
    background-image: url(../public/faq.png);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    
  }

  .faq-question {
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: bold;
    padding: 4%;
    height: 9vh;
    background-color: #ffffff96;
    color: black;
    border-bottom-left-radius: 37%;

  }
  
  .faq-icon {
    margin-left: auto;
    transition: transform 0.3s;
  }
  
  .expanded {
    transform: rotate(180deg);
  }
  .faq-answer {
    padding: 4%;
    color: white;
    font-weight: bolder;
    color: black;
    overflow: hidden;
    transition: max-height 0.3s;
  }
  
  .faq-answer.show {
    max-height: 200px; 
  }

  .email, .tel, .whatsApp{
    color: #004499;
  }

  .downloadForm{
    color: #66aaff;
    animation-name: form;
    animation-iteration-count: infinite;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;

}

@keyframes form {
    50% {
        color: #004499;

    }
}


  .downloadForm:hover{
    cursor: pointer;
    color:#ff7f50;
  }

  /* chrome desktop mode on phone */
  @media (width: 2560px) and (height: 1440px) {
    
    .AboutUs-body {
  font-size: -webkit-xxx-large;
  padding-top: 24rem;
  }



  h2 {
    font-size: 5rem;
  }

  .faq-title {
    font-size: 5rem;
    margin-bottom: 4rem;
}

  }

/* Skill for mobile and Tablet */
@media (max-width: 767px) {
    .professionalExperience {
        padding-top: 1em;
        font-size: 2em;
    }

    .AboutUs-body {
      padding-top: 50%;
    }

    
.carousel-Image{
  width: 100%;
  transform: none;
}


    .faq {
      margin-left: 0%;
      margin-right: 0%;
    }

    .faq-question {
      padding: 18% 4%;
    }
}

@media (max-width: 430px) {
  .aboutUs-div{
    height: 490px;

    margin-top: 35px;
    margin-left: 0%;
    margin-right: 0%;
    padding: 1em;
}

.aboutUs-div-2{
    height: 490px;
    margin-top: 35px;
    margin-left: 0%;
    margin-right: 0%;
    padding: 1em;
}
}


@media (min-height: 1024px) and (max-height: 1368px) {
    
  .AboutUs-body {
    padding-top: 33%;
  }
}


@media (min-width: 540px) and (max-width: 912px) {
    
  .AboutUs-body {
    padding-top: 33%;
}

.aboutUs-div-2 {
  margin-top: 12%;
  margin-bottom: 10%;
  padding: 10px;
}
}

/* very small screens */
@media (max-width: 350px) {
  .AboutUs-body {
    padding-top: 83%;
  }
  .aboutUs-div-2 {
    height: 667px;
  }

  .faq-question {
    padding: 29% 4%;
  }

}